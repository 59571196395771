import React, {useState} from 'react';

const SiteContext = React.createContext({});

// We store 2 things in our site-context:
// 1. The i18next object for multilingual purposes.
// 2. The menu object for handling the menu.
const Provider = props => {

  // We also provide a menu object with current menu status and toggles.
  const [menuState, setMenuState] = useState('closed');
  const menu = {
    menuState: menuState,
    toggleMenu: () => menuState === 'closed' ? setMenuState('open') : setMenuState('closed'),
    closeMenu: () => { if (menuState === 'open') setMenuState('closed') }
  };

  return (
    <SiteContext.Provider value={{
      menu
    }}>
      {props.children}
    </SiteContext.Provider>
  )
};

export default ({ element, props }) => (
  <Provider {...props}>
    {element}
  </Provider>
);

export { SiteContext }
