module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Albert's favoriete recepten","short_name":"Recepten","start_url":"/","background_color":"#3f7e99","theme_color":"#3f7e99","display":"minimal-ui","icon":"src/images/croissant.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
